// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-arduino-js": () => import("/opt/build/repo/src/pages/arduino.js" /* webpackChunkName: "component---src-pages-arduino-js" */),
  "component---src-pages-bfassist-js": () => import("/opt/build/repo/src/pages/bfassist.js" /* webpackChunkName: "component---src-pages-bfassist-js" */),
  "component---src-pages-discord-bot-js": () => import("/opt/build/repo/src/pages/discord_bot.js" /* webpackChunkName: "component---src-pages-discord-bot-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pichunt-js": () => import("/opt/build/repo/src/pages/pichunt.js" /* webpackChunkName: "component---src-pages-pichunt-js" */),
  "component---src-pages-polls-js": () => import("/opt/build/repo/src/pages/polls.js" /* webpackChunkName: "component---src-pages-polls-js" */),
  "component---src-pages-shooter-js": () => import("/opt/build/repo/src/pages/shooter.js" /* webpackChunkName: "component---src-pages-shooter-js" */),
  "component---src-pages-spadestools-js": () => import("/opt/build/repo/src/pages/spadestools.js" /* webpackChunkName: "component---src-pages-spadestools-js" */),
  "component---src-pages-stream-js": () => import("/opt/build/repo/src/pages/stream.js" /* webpackChunkName: "component---src-pages-stream-js" */),
  "component---src-pages-study-js": () => import("/opt/build/repo/src/pages/study.js" /* webpackChunkName: "component---src-pages-study-js" */),
  "component---src-pages-webapps-js": () => import("/opt/build/repo/src/pages/webapps.js" /* webpackChunkName: "component---src-pages-webapps-js" */),
  "component---src-pages-xibound-js": () => import("/opt/build/repo/src/pages/xibound.js" /* webpackChunkName: "component---src-pages-xibound-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

